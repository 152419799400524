import { PageProps, navigate } from "gatsby";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Heading, Text, Flex, Box } from "rebass";
import { BrandButton, BrandButtonA } from "../../components/BrandButton";
import Page from "../../components/Page";
import HeroLayoutEmpty from "../../components/HeroLayoutEmpty";
import HeroGridCard from "../../components/HeroGridCard";
import PageSection from "../../components/PageSection";
import HeroGrid from "../../components/HeroGrid";
import IndexLayout from "../../layouts";
import { FetchState, makeGraphqlRequest } from "../../lib/api";
import { RequestEmailConfirmation } from "../../lib/mutations";
import * as globalStyles from "../../styles/Global.module.css";
import * as styles from "./Verify.module.css";

interface VerifyEmailPageLocationState {
  email?: string;
}

const VerifyEmailPage = ({
  location,
}: PageProps<{}, {}, VerifyEmailPageLocationState>) => {
  // Using Gatsby `navigate(to, { state })` option, we pass the user's email in
  // from the signup page using location state
  const email = location.state?.email;

  // Redirect users to the signup page if we have no email provided in state
  useEffect(() => {
    if (!email) {
      navigate("/signup/");
    }
  }, [email]);

  const [{ isError, isSuccessful }, setState] = useState<FetchState>({
    isError: false,
    isLoading: false,
    isSuccessful: false,
  });

  const onResendClick = useCallback(async () => {
    setState({
      isError: false,
      isLoading: true,
      isSuccessful: false,
    });

    const data = await makeGraphqlRequest("auth", {
      query: RequestEmailConfirmation,
      variables: { email },
    });

    if (!data?.data?.requestEmailConfirmation?.success) {
      setState({
        isError: true,
        isLoading: false,
        isSuccessful: false,
      });

      return;
    }

    setState({
      isError: false,
      isLoading: false,
      isSuccessful: true,
    });
  }, [email]);

  return (
    <IndexLayout>
      <Page>
        <HeroGrid
          style={{ position: "relative", overflow: "hidden", rowGap: "0" }}
        >
          <Helmet>
            <title>Verify Email | Skodel</title>
            <meta property="og:title" content="Verify Email | Skodel" />
          </Helmet>
          <PageSection
            className={styles.heroAboutUs}
            color="white"
            id="top"
            css={{ minHeight: "100vh" }}
          >
            <Box className={`${styles.heroAboutUsStripes} brandStripes`}>
              <Box className={styles.heroAboutUsStripesInner}>
                <Box className={styles.heroAboutUsStripe1} />
                <Box className={styles.heroAboutUsStripe2} />
                <Box className={styles.heroAboutUsStripe3} />
                <Box className={styles.heroAboutUsStripe4} />
              </Box>
            </Box>
            <HeroGridCard style={{ margin: "208px auto 0" }}>
              <HeroLayoutEmpty>
                <Flex justifyContent="center">
                  <Heading
                    fontSize={2}
                    color="rgb(91, 157, 233)"
                    justifyContent="center"
                    style={{ display: "flex", borderRadius: "20px" }}
                    p={2}
                    px={3}
                    bg="rgba(70, 156, 240, 0.1)"
                  >
                    Account Created
                  </Heading>
                </Flex>
                <Heading
                  style={{
                    maxWidth: "744px",
                    margin: "10px auto 0",
                    fontSize: "42px",
                    lineHeight: "58px",
                    color: "#222A41",
                    textAlign: "center",
                  }}
                >
                  Verify Email
                </Heading>
                <Text
                  style={{
                    maxWidth: "716px",
                    margin: "24px auto 0",
                    fontSize: "17px",
                    lineHeight: "30px",
                    color: "#7b7b7b",
                    textAlign: "center",
                  }}
                >
                  You have successfully signed up to Skodel. Before you can get
                  started, please check your email for a link to verify your
                  account. Then, head to{" "}
                  <a
                    href="https://app.skodel.com"
                    style={{
                      color: "#AA63FF",
                      fontWeight: 700,
                    }}
                  >
                    app.skodel.com
                  </a>{" "}
                  to sign in.
                </Text>
                <Flex justifyContent="center" pt={5} pb={4} flexWrap="wrap">
                  <BrandButtonA
                    className={`${globalStyles.btn}`}
                    onClick={onResendClick}
                    style={{
                      padding: "12px 30px",
                      backgroundColor: "#AA63FF",
                      color: "#FFFFFF",
                      boxShadow: "0 8px 26px 0 rgba(170, 99, 255, 0.30)",
                      marginRight: "8px",
                    }}
                  >
                    Resend email
                  </BrandButtonA>
                  <BrandButton
                    className={`${globalStyles.btn}`}
                    to="/contact-us"
                    style={{
                      cursor: "pointer",
                      padding: "12px 30px",
                      backgroundColor: "rgba(170, 99, 255, .2)",
                      color: "#AA63FF",
                    }}
                  >
                    Contact us
                  </BrandButton>
                </Flex>
                {isError && (
                  <Text color="#e74c3c" fontWeight="700" textAlign="center">
                    Something went wrong
                  </Text>
                )}
                {isSuccessful && (
                  <Text color="#279D74" fontWeight="700" textAlign="center">
                    Verification email sent
                  </Text>
                )}
              </HeroLayoutEmpty>
            </HeroGridCard>
          </PageSection>
        </HeroGrid>
      </Page>
    </IndexLayout>
  );
};

export default VerifyEmailPage;
