// extracted by mini-css-extract-plugin
export var contactFormIcon = "Verify-module--contact--form-icon--A5KHs";
export var contactInput = "Verify-module--contact--input--UAAE7";
export var contactTextarea = "Verify-module--contact--textarea--2okqm";
export var heroAboutUs = "Verify-module--hero--about-us--3ibx7";
export var heroAboutUsStripe1 = "Verify-module--hero--about-us-stripe-1--+YTK+";
export var heroAboutUsStripe2 = "Verify-module--hero--about-us-stripe-2--aCnxY";
export var heroAboutUsStripe3 = "Verify-module--hero--about-us-stripe-3--gNsl3";
export var heroAboutUsStripe4 = "Verify-module--hero--about-us-stripe-4--ZKGfa";
export var heroAboutUsStripe5 = "Verify-module--hero--about-us-stripe-5--uAyJD";
export var heroAboutUsStripe6 = "Verify-module--hero--about-us-stripe-6--hX1dc";
export var heroAboutUsStripe7 = "Verify-module--hero--about-us-stripe-7--Znrv0";
export var heroAboutUsStripes = "Verify-module--hero--about-us-stripes--VKB43";
export var heroAboutUsStripesInner = "Verify-module--hero--about-us-stripes-inner--7AtDH";